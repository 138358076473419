/** @format */

import React from "react";
import { Router as CSRourter } from "@reach/router";
import Productos from "./productos/Productos";
import Layout from "../components/Layout";
import DetalleProducto from "./productos/DetalleProducto";

const App = () => {
  return (
    <Layout>
      <CSRourter>
        <DetalleProducto path="/productos/detalle-producto/:id" />
        <Productos path="/productos" />
      </CSRourter>
    </Layout>
  );
};

export default App;
